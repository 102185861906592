// @author vijay Badha
<template>
  <div id="bulkImport" @click="windowClick($event)">
    <mds-modal
      class="bulkModal mds-modal"
      title="Import"
      v-model="toggle"
      :width="'1200px'"
      action-required>
      <div>
        <div class="file-details-container">
          <h4 style="margin: 0; margin-right: 16px">File Name</h4>
          <p style="margin: 0">{{fileName}}</p>
        </div>
        <div class="" style="margin-top: 0">
          <mds-layout-grid>
            <mds-row style="margin: 0">
              <mds-col :cols="2" class="bulk-left-col">
                <h3>Settings</h3>
                <p>
                  Specify if your file contains a header row and map the
                  required columns to continue importing your data.
                </p>
                <mds-select
                  size="medium"
                  :disabled="!isUploadedContent || disableHeaderSelectDropDown"
                  label="Header Row"
                  style="margin-bottom: 16px"
                  v-model="headerType"
                  @change="onHeaderTypeChange"
                >
                  <option value="noHeader">No Header</option>
                  <option value="firstRow">First Row</option>
                </mds-select>
                <mds-select
                  v-if="showTemplateDropDown"
                  :disabled="!isUploadedContent && existingTemplateData.length < 0"
                  size="medium"
                  label="Mapping Template"
                  microcopy-above="Select a template to use to quickly map the columns of your file."
                  v-model="selectedTemplate"
                  @change="onTemplateChange()">
                  <option value="">Select a template</option>
                  <option
                    v-for="template in existingTemplateData"
                    :key="template.templateid"
                    :value="template.templateid">
                    {{ template.templatename }}
                  </option>
                </mds-select>
                <mds-select
                  :disabled="!isUploadedContent "
                  size="medium"
                  label="Entity Level"
                  style="margin-top: 16px"
                  v-if="(selectedTemplate == templateFileTypes.MultipleDataPointsPerRow || selectedTemplate == templateFileTypes.GroupDataPointsPerRow) && entityTypeList.length > 0"
                  v-model="selectedEntityType"
                  @change="onEntityTypeChange()">
                  <option value=0>Select entity type</option>
                  <option
                    v-for="entity in entityTypeList"
                    :key="entity.entitytypeid"
                    :value="entity.entitytypeid">
                    {{ entity.entitytypedescription }}
                    </option>
                </mds-select>
              </mds-col>
              <mds-col :cols="10" class="bulk-right-col">
                <mds-tabs
                  v-if="!isUploadedContent"
                  style="margin: 10px 0"
                  class="bulkTabs"
                  :key="tabKey">
                  <!-- showPotentialIssues -->
                  <template
                  v-for="(tabs, tabIndex) in tabsContent">
                    <mds-tabs-item
                        v-if="isPotentialIssuesHidden(tabs)"
                        :icon="tabs.icon"
                        :class="{
                        error: tabs.id == 'errors',
                        potential: tabs.id == 'potentialIssues',
                        }"
                        :key="tabIndex"
                        :active="activeTab == tabs.id"
                        :text="tabs.text"
                        v-on:mds-tabs-item-active="onTabsClick(tabs)"
                    ></mds-tabs-item>

                  </template>
                </mds-tabs>
                <div
                  style="
                    margin: auto;
                    width: 100%;
                    overflow: scroll;
                    padding: 5px;
                  "
                  :style="isUploadedContent ? 'height:90%':'height:75%'"
                  >
                  <table
                    :key="dataContentTableKey"
                    style="
                      width: max-content;
                      position: relative;border-spacing: 0;
                    "
                  >
                    <template v-for="(rows, index) in bulkData">
                      <tr
                        :key="index"
                        v-if="showTableTr(rows, index)"
                        :style="
                          index == 0
                            ? { 'border-bottom': 'none' }
                            : { 'border-bottom': '1px solid' }
                        "
                        style="border-top: none;"
                      >
                        <td
                          style="
                            width: 50px;
                            padding: 10px 0;
                            padding-right: 10px;
                            text-align: center;border-top: none;
                          "
                           :style="
                          index == 0
                            ? { 'border-bottom': 'none' }
                            : { 'border-bottom': '1px solid' }
                        "
                          v-if=" !isUploadedContent && (rows.isError || rows.isPotentialIssue) "
                        >
                          <!-- showErrorToolTip:false, -->
                          <!-- showPotentialToolTip:false, -->
                          <div
                            v-if="rows.isError"
                            :id="index + 'errorIconError'"
                            style="width: fit-content; margin: auto"
                            @mouseover="rows.showErrorToolTip = true"
                            @mouseleave="rows.showErrorToolTip = false"
                            class="errorToolTip"
                          >
                            <mds-icon
                              name="alert-fill"
                              class="red-color"
                              size="small"
                            ></mds-icon>
                            <mds-tooltip
                              variation="error"
                              class="red-color-background"
                              :triggered-by="index + 'errorIconError'"
                              :visible="rows.showErrorToolTip"
                              :position="['right-center']"
                            >
                             <div class="toolTipListDiv">
                                <mds-list-group >
                                        <mds-list-group-item
                                          v-for="(msg,
                                          indexMsg) in rows.isErrorMessage"
                                          :key="indexMsg"
                                          :text="msg"
                                          ></mds-list-group-item>
                                      </mds-list-group>
                             </div>
                              <!-- {{rows.message}} -->
                            </mds-tooltip>
                          </div>
                          <div
                            v-if="rows.isPotentialIssue"
                            :id="index + 'errorIconPotential'"
                            style="width: fit-content; margin: auto"
                            @mouseover="rows.showPotentialToolTip = true"
                            @mouseleave="rows.showPotentialToolTip = false"
                          >
                            <mds-icon
                              v-if="rows.isPotentialIssue"
                              name="alert-fill"
                              class="yellow-color"
                              size="small"
                            ></mds-icon>
                            <mds-tooltip
                              variation="error"
                              class="yellow-color-background"
                              :triggered-by="index + 'errorIconPotential'"
                              :visible="rows.showPotentialToolTip"
                              :position="['right-center']"
                            >
                             <div class="toolTipListDiv">
                                <mds-list-group >
                                        <mds-list-group-item
                                          v-for="(msg,
                                          indexMsg) in rows.isPotentialIssueMessage"
                                          :key="indexMsg"
                                          :text="msg"
                                          ></mds-list-group-item>
                                      </mds-list-group>
                             </div>
                            </mds-tooltip>
                          </div>
                        </td>
                        <td :style="
                          index == 0
                            ? { 'border-bottom': 'none' }
                            : { 'border-bottom': '1px solid' }
                        " style="border-top:none" v-else></td>

                        <td
                          style="
                            width: 100px;
                            padding: 10px 0;
                            padding-right: 10px;
                            border-top:none;
                          "
                          :style="
                          index == 0
                            ? { 'border-bottom': 'none' }
                            : { 'border-bottom': '1px solid' }
                        "
                          v-if="!isUploadedContent"
                        >
                          <mds-checkbox
                            v-model="rows.isSelected"
                            @change="rowCheckBoxChange($event, index)"
                          ></mds-checkbox>
                        </td>
                        <template v-for="(col, indexCol) in rows.row">
                          <td
                            :key="indexCol"
                            v-if=" 
                                index == 0"
                            style="
                              width: 250px;
                              padding: 10px 0;
                              padding-right: 10px;border-top:none;
                            "
                            :style="
                          index == 0
                            ? { 'border-bottom': 'none' }
                            : { 'border-bottom': '1px solid' }
                        "
                          >
                            <span
                              v-if="col.col"
                              :class="index == 0 ? 'bulk-headers' : ''"
                            >
                              {{ col.col }}
                              <span>
                                <span 
                                   v-if="isUploadedContent" style="margin-left: 20px">
                                  <mds-button
                                    variation="icon-only"
                                    icon="pencil"
                                    type="button"
                                    :id="'search' + indexCol"
                                    size="small"
                                    :disabled="disableSearch"
                                    class="searchInput"
                                    @click="openFocusSearchResult(indexCol)"
                                  ></mds-button>
                                </span>
                              </span>
                            </span>
                           
                            <mds-search-field
                              v-else
                              placeholder="Map Column"
                              :id="'search' + indexCol"
                              variation="input"
                              label="Search"
                              class="searchField"
                              :disabled="headerDropDownList.length == 0"
                               v-model="col.searchText"
                              @click="openFocusSearchResult(indexCol)"
                              @blur="
                                closeSearchResult($event, 'search' + indexCol)
                              "
                              @input="
                                filterSearchResult($event)
                              "
                            ></mds-search-field>
                            <div style="position: absolute">
                              <mds-search-results
                                v-if="col.showSearchResult"
                                :class="'search' + indexCol"
                                :width="'300px'"
                                class="searchResultClass"
                                 style="max-height: 300px;"
                              >
                                <!-- <mds-section
                                  border="none"
                                  :size="7"
                                  :bold="true"
                                  title="Frequently Used"
                                  class="searchResultClass"
                                  style="max-height: 300px;overflow: hidden"
                                > -->
                                  <mds-list-group class="searchResultClass" >
                                    <mds-list-group-item
                                      class="searchResultClass"
                                      v-for="(entity,
                                      indexList) in rowsFilteredResult"
                                      :key="indexList"
                                      :text="entity"
                                      @mds-list-item-clicked="
                                        col.col = entity;
                                        col.showSearchResult = false;
                                      "
                                    ></mds-list-group-item>
                                  </mds-list-group>
                                <!-- </mds-section> -->
                              </mds-search-results>
                            </div>
                          </td>
                          <td
                            v-if="index != 0"
                            :key="indexCol"
                            style="
                              width: 200px;
                              padding: 10px 0;
                              padding-right: 10px;
                              line-break: anywhere;border-top:none;
                            "
                            :style="
                          index == 0
                            ? { 'border-bottom': 'none' }
                            : { 'border-bottom': '1px solid' }
                        "
                          >
                            <div
                              v-if="!isUploadedContent && (col.isError || col.isPotentialIssue || editAllFields)" 
                              style="color: red"
                            >
                              <mds-input
                                :class="{
                                  potentialInput: col.isPotentialIssue,
                                }"
                                :error="col.isError || col.isPotentialIssue"
                                hidden-label
                                :value="col.col"
                                @change="(e) => (col.col = e.target.value)"
                                label="Default"
                              ></mds-input>
                            </div>
                            <div v-else>
                              <span>
                                {{ col.col }}
                              </span>
                            </div>
                          </td>
                        </template>
                        <!-- <td
                        v-for="(col, indexCol) in rows"
                        :key="indexCol"
                        style="
                          width: 200px;
                          padding: 10px 0;
                          padding-right: 10px;
                        "
                      >
                        <div v-if="checkValidation(index, indexCol)" style="color: red">
                          {{ col }}
                        </div>
                        <div v-else>
                          <span :class=" headerType == 'firstRow' && index == 0 ? 'bulk-headers'  : '' ">
                              {{ col }} <span><mds-icon name="pencil" size="small" v-if="headerType == 'firstRow' && index == 0"></mds-icon></span>
                          </span>
                        </div>
                      </td> -->
                      </tr>
                    </template>
                  </table>

                 
                </div>
                <div>
                  <div style="display: flex; margin-top: 2%">
                    <div
                      style="
                        display: flex;
                        width: 50%;
                        justify-content: flex-start;
                        align-items: center;
                      "
                    >
                      <h4 style="margin: 0; margin-right: 5%">
                        Total Rows: {{ totalRowsLength }}
                      </h4>
                      <h4 style="margin: 0" v-if="!isUploadedContent">
                        Rows Selected: {{ selectedRowsLength }}
                      </h4>
                    </div>
                    <div style="width: 50%">
                      <mds-button-container right-aligned>
                        <button-component
                          :onClick="modalClose"
                          buttonName="Cancel"
                          buttonVariation="secondary"
                        ></button-component>
                        <button-component
                          :onClick="onProcessData"
                          :buttonName="saveDataButtonName"
                          buttonVariation="primary"
                        ></button-component>
                      </mds-button-container>
                    </div>
                  </div>
                </div>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
      </div>
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <button-component
            :onClick="modalClose"
            buttonName="cancel"
            buttonVariation="icon-only"
            iconName="remove"
          ></button-component>
        </mds-button-container>
      </template>
    </mds-modal>
    <unmatched-items-modal
      v-if="showUnmatchedModal"
      :unmatchedItems="convertedUnmatchedObject"
      :selectedEntityTypeId="selectedEntityType"
      @selectedUnmatchedItems="changeUnmatchedItems"
      @closeUnmatchedItems="closeUnmatchedModal"
    ></unmatched-items-modal>
    <loader-component v-if="showLoader"></loader-component>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>
  </div>
</template>
<script>
import MdsModal from "@mds/modal";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsSelect from "@mds/select";
import MdsSearchField from "@mds/search-field";
import Enums from "../../config/Enums.json";
// import uploadedJson from "../../store/modules/uploadedBulk.json";
import { MdsTabs, MdsTabsItem } from "@mds/tabs";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsIcon from "@mds/icon";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
// import SearchResultsComponent from "../ui_component/SearchResultsComponent.vue";
import MdsInput from "@mds/input";
import MdsTooltip from "@mds/tooltip";
import MdsCheckbox from "@mds/checkbox";
import UnmatchedItemsModal from "./UnmatchedItemsModal";
// import { checkUnmatchedItems } from "../../services/bulk_operations_service.js";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import { mapGetters, mapActions } from "vuex";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import { MdsSearchResults } from "@mds/search-results";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
// import MdsSection from "@mds/section"; 
// import InputComponent from '../ui_component/inputComponent.vue';
// import { MdsSearchResults, MdsSearchResultsSection } from '@mds/search-results'
export default {
  components: {
    MdsButton,
    MdsModal,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSelect,
    MdsSearchField,
    MdsTabs,
    MdsTabsItem,
    MdsIcon,
    MdsInput,
    LoaderComponent,
    //  MdsTabsItem,
    // MdsButton,
    MdsButtonContainer,
    ButtonComponent,
    // SearchResultsComponent,
    MdsTooltip,
    MdsCheckbox,
    UnmatchedItemsModal,
    NotificationComponent,
    MdsSearchResults,
    MdsListGroup,
    MdsListGroupItem,
    // MdsSection,
    // InputComponent,
  },
  props: ["EntityId","fileData","reviewData","isReviewAction","fileName","stagingId","showTemplateDropDown","fileTemplateId","disableHeaderSelectDropDown","showPotentialIssues","isUnmatchedItemsDisplayed","screenName","editAllFields"],
  data() {
    return {
      templateFileTypes:Enums.BulkOperationsFileType,
      headerDropDownList:[],
      convertReviewData:[],
      HighestIndexLengthOfUploadedFile:0,
      searchResultKey:0,
      disableSearch: true,
      entityTypeList: [],
      selectedEntityType: 0,
      entityTypeId: 1,
      selectedTemplate: null,
      existingTemplateData: [],
      notificationMessage: "",
      showNotification: false,
      notificationType: "",
      notificationKey: "",
      showLoader: false,
      entityList: [],
      convertedUnmatchedObject: [],
      showUnmatchedModal: false,
      tabKey: 0,
      errorListLength: 0,
      noIssueLength: 0,
      potentialIssueLength: 0,
      totalRowsLength: 0,
      selectedRowsLength: 0,
      activeTab: "allItems",
      dataContentTableKey: 0,
      isUploadedContent: true,
      saveDataButtonName: "Process",
      rowsFilteredResult: [],
      convertedData: [],
      emptyArrayForNoHeader: [],
      headerType: "noHeader",
      toggle: true,
      bulkData: [],
      tabsContent: [
        {
          id: "allItems",
          text: "All Items",
          icon: "",
          active: true,
        },
        {
          id: "noIssues",
          text: "No Issues",
          icon: "",
          active: false,
        },
        {
          id: "errors",
          text: "Errors",
          icon: "alert-fill",
          active: false,
        },
        {
          id: "potentialIssues",
          text: "Potential Issues",
          icon: "alert-fill",
          active: false,
        },
      ],
      validationDetails:{},
      checkUnmactchedObject: {
        header: ["rowid"],
        data: [],
        entitytypeid: 1,
        filename: this.fileName,
        filetype:0
      },
      importPayload:{
        header: [],
        data: [],
        fileName:this.fileName,
        fileType: 3,
        fileTemplate: "",
        EntityTypeId: 2,
        stagingDataId: 0,
        EntityId:0
      },
      getHeadersPayload: {
        fileType: "",
        entityTypeId: "",
      },
    };
  },
  computed: {
    ...mapGetters("entity", ["getEntityTypes"]),
    ...mapGetters("bulkOperations", [
      "getUnmatchedItemDetails",
      "getExistingTemplatesDetails",
      "getHeadersListDetails",
      "getImportedData"
    ]),
    //
  },
  async mounted() {
    this.showLoader = true;
    // this.isUploadedContent=false
    if(this.showTemplateDropDown){
        if (this.getExistingTemplatesDetails.length == 0) {
        await this.fetchExistingTemplates(false);
        if (!this.getExistingTemplatesDetails.isError) {
            this.existingTemplateData = this.getExistingTemplatesDetails.data;
        }
        } else {
        this.existingTemplateData = this.getExistingTemplatesDetails.data;
        }
    }else{
        this.selectedTemplate=this.fileTemplateId
        this.headerType="firstRow"
        // this.onHeaderTypeChange()
        
    }
    if (this.isReviewAction === true) {
          this.isUploadedContent=false
          this.headerType="firstRow"
          this.saveDataButtonName = "Import"
          this.selectedTemplate=this.reviewData.data.filedto.filetype
          await this.onTemplateChange()
          this.selectedEntityType=this.reviewData.data.filedto.entitytypeid
          this.importPayload.stagingDataId = this.stagingId
          let head=[]
          for(let i=0;i<this.reviewData.data.filedto.header.length;i++){
            if(i != this.reviewData.data.filedto.header.length-1){
              head.push(this.reviewData.data.filedto.header[i])
            }
          }
          this.convertReviewData.push(head)
          for(let j=0;j<this.reviewData.data.filedto.data.length;j++){
            let data=[]
            for(let k=0;k<this.reviewData.data.filedto.data[j].length;k++){
              if(k != this.reviewData.data.filedto.data[j].length-1){
                data.push(this.reviewData.data.filedto.data[j][k])
              }
            }
            this.convertReviewData.push(data)
          }
          await this.onLoadFileDetails(this.convertReviewData)
          this.validationDetails = this.reviewData.data.filevalidatordto
          await this.validationErrors()
          await this.checkListLength()
    } else {
       await this.onLoadFileDetails(this.fileData)
    }
   
    this.showLoader = false;
  },
  methods: {
    ...mapActions("entity", ["fetchEntityTypes"]),
    ...mapActions("bulkOperations", [
      "fetchUnmatchedItemDetails",
      "fetchExistingTemplates",
      "fetchHederList",
      "importFileData"
    ]),
    isPotentialIssuesHidden(tabsData){
        if(tabsData.id == 'potentialIssues'){
            if(this.showPotentialIssues == true){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    },
    async onLoadFileDetails(fileData){
      await this.checkHighestIndexLength(fileData)
    //  var isCheckUploadedData = await this.checkUploadedData(fileData)
    //  if(isCheckUploadedData != null){
    //    this.$emit("incorrectUploadedData",isCheckUploadedData)
    //  }else{
      this.convertedData = await this.convertUploadedFileJson(fileData);
      this.bulkData = this.convertedData;
      // this.rowsFilteredResult = this.rows;
      this.totalRowsLength = fileData.length;
      // this.bulkData = this.fileData;
      if(this.isUploadedContent && !this.disableHeaderSelectDropDown){
          
        // checking the number of columns and adding the empty array for no headers
        var objRow = {
              row: [],
              isSelected: true,
            };
        for(var i=0;i<this.HighestIndexLengthOfUploadedFile;i++){
          var obj = {
                col: "",
                isError: false,
                isPotentialIssue: false,
                showSearchResult: false,
                message: "",
                searchText:"",
              };
              objRow.row.push(obj);
        }
        this.emptyArrayForNoHeader = objRow;
      this.bulkData.splice(0, 0, this.emptyArrayForNoHeader);
      }
      if(this.disableHeaderSelectDropDown){
        this.totalRowsLength = this.fileData.length - 1;
      }
    },
    // bindEventHandlers() {
    //   window.addEventListener("click", this.windowClick, true);
    // },
    windowClick(e) {
      if(this.bulkData.length > 0){
        if (
        e.target.className != "mds-search-field__input___VueMDS3Demo" &&
        e.target.classList[0] != "searchResultClass" &&
        e.target.parentElement.parentNode.classList[0] != "searchResultClass" &&
        e.target.localName != "svg" && e.target.className != "mds-checkbox__input___VueMDS3Demo"
      ) {
        if(this.isUploadedContent){
          for (var i = 0; i < this.bulkData[0].row.length; i++) {
          if(this.bulkData[0].row[i].showSearchResult){
            this.bulkData[0].row[i].showSearchResult = false;
           
          }
          if(i == this.bulkData[0].row.length-1){
             this.dataContentTableKey+=1 
          }
        }
        }
        
        //  
      }

      }
      
    },
    // called when template value is changed
    async onTemplateChange() {
      this.selectedEntityType = 0;
      if (this.selectedTemplate) {
        this.showLoader = true;
        if (this.selectedTemplate == 1 || this.selectedTemplate == 4) {
          this.getHeadersPayload.entityTypeId = this.entityTypeId;
          this.getHeadersPayload.fileType = this.selectedTemplate;
          this.rowsFilteredResult=[]
          await this.getHeaderDetails(this.getHeadersPayload);
        } else {
           this.headerDropDownList=[]
          this.disableSearch = true;
          if (this.getEntityTypes.length == 0) {
            await this.fetchEntityTypes();

            this.entityTypeList = this.getEntityTypes;
          } else {
            this.entityTypeList = this.getEntityTypes;
          }
        }
        this.showLoader = false;
      } else {
        this.selectedTemplate = null;
         this.headerDropDownList=[]
      }

      // Clearing the headers which are selected bocz when the template is changed there may be chance of no header which has selected
      if (this.headerType == "noHeader") {
        for(let i=0;i<this.bulkData[0].row.length;i++){
         this.bulkData[0].row[i].col=""
        }
      }
    },
    async getHeaderDetails() {
      await this.fetchHederList(this.getHeadersPayload);
      if (!this.getHeadersListDetails.isError) {
        this.headerDropDownList=this.getHeadersListDetails.data
        this.rowsFilteredResult = this.getHeadersListDetails.data;
        this.disableSearch = false;
      }
    },
    async onEntityTypeChange() {
      if (this.selectedEntityType != 0) {
        this.showLoader = true;
        this.getHeadersPayload.entityTypeId = this.selectedEntityType;
        this.getHeadersPayload.fileType = this.selectedTemplate;
        this.rowsFilteredResult=[]
        await this.getHeaderDetails(this.getHeadersPayload);
        this.showLoader = false;
      } else {
         this.headerDropDownList=[]
         this.rowsFilteredResult=[]
         this.disableSearch = true;
         this.selectedEntityType = 0;
      }
       // Clearing the headers which are selected bocz when the template is changed there may be chance of no header which has selected
      if (this.headerType == "noHeader") {
        for(let i=0;i<this.bulkData[0].row.length;i++){
         this.bulkData[0].row[i].col=""
        }
      }
    },

    // event called when cancel is clicked in the unmatched model
    closeUnmatchedModal() {
      this.showUnmatchedModal = false;
    },
    modalClose() {
      this.toggle = true;
      this.$emit("importModalClose");
    },
    // method is used for the checkboxes changed
    async rowCheckBoxChange(event, index) {
      if (index == 0) {
        if (event) {
          if (this.activeTab == "allItems") {
            this.selectedRowsLength=0
          }
          for (var i = 0; i < this.bulkData.length; i++) {
            if (i != 0) {
              if (this.activeTab == "allItems") {
                 this.bulkData[i].isSelected = true;
              this.selectedRowsLength++;
                } else if (this.activeTab == "errors" && this.bulkData[i].isError) {
                    this.bulkData[i].isSelected = true;
              this.selectedRowsLength++;
                } else if (
                  this.activeTab == "noIssues" &&
                  !this.bulkData[i].isError &&
                  !this.bulkData[i].isPotentialIssue
                ) {
                    this.bulkData[i].isSelected = true;
              this.selectedRowsLength++;
                } else if (
                  this.activeTab == "potentialIssues" &&
                  this.bulkData[i].isPotentialIssue
                ) {
                   this.bulkData[i].isSelected = true;
              this.selectedRowsLength++;
                }
            }
          }
        } else {
          for (var j = 0; j < this.bulkData.length; j++) {
            if (j != 0) {
               if (this.activeTab == "allItems") {
                 this.bulkData[j].isSelected = false;
              this.selectedRowsLength--;
                } else if (this.activeTab == "errors" && this.bulkData[j].isError) {
                    this.bulkData[j].isSelected = false;
              this.selectedRowsLength--;
                } else if (
                  this.activeTab == "noIssues" &&
                  !this.bulkData[j].isError &&
                  !this.bulkData[j].isPotentialIssue
                ) {
                    this.bulkData[j].isSelected = false;
              this.selectedRowsLength--;
                } else if (
                  this.activeTab == "potentialIssues" &&
                  this.bulkData[j].isPotentialIssue
                ) {
                   this.bulkData[j].isSelected = false;
              this.selectedRowsLength--;
                }
              // this.bulkData[j].isSelected = false;
            }
          }
        }
        await this.onAllSelectCheckBoxCheck();
      } else {
        if (event) {
          this.selectedRowsLength++;
        } else {
          if (this.bulkData[0].isSelected) {
            this.bulkData[0].isSelected = false;
          }
          this.selectedRowsLength--;
        }
        await this.onAllSelectCheckBoxCheck();
      }
    },

    // used to hide or display the rows in the table based on tabs click
    // ex:- if it is error tab it will show only error data...
    showTableTr(rows, index) {
      if (index == 0) {
        return true;
      } else if (this.isUploadedContent) {
        return true;
      } else {
        if (this.activeTab == "allItems") {
          return true;
        } else if (this.activeTab == "errors" && rows.isError) {
          return true;
        } else if (
          this.activeTab == "noIssues" &&
          !rows.isError &&
          !rows.isPotentialIssue
        ) {
          return true;
        } else if (
          this.activeTab == "potentialIssues" &&
          rows.isPotentialIssue
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    openFocusSearchResult(id) {
      for (var i = 0; i < this.bulkData[0].row.length; i++) {
        if (i == id) {
          this.bulkData[0].row[i].showSearchResult = true;
          this.searchResultKey +=1
          if(this.headerType=="firstRow"){
            this.dataContentTableKey+=1
          }
        } else {
          this.bulkData[0].row[i].showSearchResult = false;
        }
      }
       this.rowsFilteredResult = this.getHeadersListDetails.data; 
      // this.$el.querySelector("." + id).classList.add("show");
    },
    closeSearchResult(e, id) {
      this.$el.querySelector("." + id).classList.remove("show");
    },
    filterSearchResult(event) {
      if (event != undefined && event.length > 0) {
        this.rowsFilteredResult = this.getHeadersListDetails.data.filter((item) =>
          item.toLowerCase().includes(event.toLowerCase())
        );
        //  this.$el.querySelector('.'+id).classList.add("show");
      } else {
        this.rowsFilteredResult = this.getHeadersListDetails.data;
        //    this.$el.querySelector('.'+id).classList.remove("show");
      }
    },

    // method is called on the header type is changed
    onHeaderTypeChange() {
      if (this.headerType == "noHeader") {
        this.bulkData.splice(0, 0, this.emptyArrayForNoHeader);
        this.totalRowsLength = this.fileData.length;
        // this.rowsFilteredResult = [];
      } else {
        this.bulkData.splice(0, 1);
        this.totalRowsLength = this.fileData.length - 1;
      }
    },

    // method gets called when the tabs are clicked
    async onTabsClick(tabs) {
      this.activeTab = tabs.id;
      await this.onAllSelectCheckBoxCheck();
    },

    // method is used to check the checkboxes are selected on tab change
    onAllSelectCheckBoxCheck() {
      if (this.activeTab == "allItems") {
        for (var a = 0; a < this.bulkData.length; a++) {
          if (a != 0) {
            if (!this.bulkData[a].isSelected) {
              this.bulkData[0].isSelected = false;
              break;
            } else {
              if (a == this.bulkData.length - 1) {
                this.bulkData[0].isSelected = true;
              }
            }
          }
        }
      } else if (this.activeTab == "noIssues") {
        for (var n = 0; n < this.bulkData.length; n++) {
          if (n != 0) {
            if (
              !this.bulkData[n].isPotentialIssue &&
              !this.bulkData[n].isError
            ) {
              if (!this.bulkData[n].isSelected) {
                this.bulkData[0].isSelected = false;
                break;
              }else {
              if (n == this.bulkData.length - 1) {
                this.bulkData[0].isSelected = true;
              }
            }
            }
          }
        }
      } else if (this.activeTab == "errors") {
        for (var e = 0; e < this.bulkData.length; e++) {
          if (e != 0) {
            if (this.bulkData[e].isError) {
              if (!this.bulkData[e].isSelected) {
                this.bulkData[0].isSelected = false;
                break;
              }
            } else {
              if (e == this.bulkData.length - 1) {
                this.bulkData[0].isSelected = true;
              }
            }
          }
        }
      } else if (this.activeTab == "potentialIssues") {
        for (var p = 0; p < this.bulkData.length; p++) {
          if (p != 0) {
            if (this.bulkData[p].isPotentialIssue) {
              if (!this.bulkData[p].isSelected) {
                this.bulkData[0].isSelected = false;
                break;
              }
            } else {
              if (p == this.bulkData.length - 1) {
                this.bulkData[0].isSelected = true;
              }
            }
          }
        }
      }
    },

   async importUploadedFileData(){
      await this.convertJsonObjectToArray()
      if(this.importPayload.data.length > 0){
      this.importPayload.fileType=parseInt(this.selectedTemplate) 
      this.importPayload.EntityTypeId=parseInt(this.selectedEntityType)
      this.importPayload.EntityId=this.EntityId; 
      await this.importFileData({data:this.importPayload,screenName:this.screenName})
      if(this.getImportedData.isError && this.getImportedData.status == 400){
        if(this.getImportedData.data.statuscode == 1 || this.getImportedData.data.statuscode == 400){
          this.notificationMessage =this.getImportedData.data.message;
          this.notificationType = "error";
          this.notificationKey = "error-default"
          this.showNotification = true;
        }else{
        this.validationDetails = this.getImportedData.data
        await this.validationErrors();
            this.dataContentTableKey += 1;
            this.selectedRowsLength=0
            this.isUploadedContent = false;
          this.importPayload.stagingDataId=this.getImportedData.data.stagingdataid
            if (!this.isUploadedContent) {
              this.headerType = "firstRow";
            
              for(var c=0;c<this.convertedData.length;c++){
               
                if(c !=0){
                  if(this.convertedData[c].isSelected){
                      this.selectedRowsLength+=1;
                  }
                  }
              }
            }
            // changing button name
            this.saveDataButtonName = "Import";
            await this.checkListLength()
      }
      }else if(!this.getImportedData.isError && (this.getImportedData.status == 201 || this.getImportedData.status == 200)){
         this.toggle = true;
        this.$emit("dataImportedStatus",this.getImportedData)
      }
      }else{
        this.notificationMessage ='No data found in the file. Please upload a file with at least one entry of data.';
        this.notificationKey = "error-default"
        this.notificationType = "error";
        this.showNotification = true;
      }
    },
    checkListLength(){
        this.errorListLength = 0
        this.potentialIssueLength = 0
        this.noIssueLength=0
         if (this.isReviewAction === true) {
        this.selectedRowsLength=0;
          this.totalRowsLength =  this.convertReviewData.length-1
           for(var c=0;c<this.convertedData.length;c++){
                if(c !=0){
                  if(this.convertedData[c].isSelected){
                      this.selectedRowsLength+=1;
                  }
                  }
              }
         }else{
           
        this.totalRowsLength = this.fileData.length - 1;
         }
      //  <summary> this for loop is used to check the length of list </summary>
            for (var i = 0; i < this.bulkData.length; i++) {
              // condition for i !=0 is bcoz 0 index is headers
              if(i!=0){
                  if (this.bulkData[i].isError) {
                    this.errorListLength++;
                  }
                  if (this.bulkData[i].isPotentialIssue) {
                    this.potentialIssueLength++;
                  }
                  if (
                    !this.bulkData[i].isError &&
                    !this.bulkData[i].isPotentialIssue
                  ) {
                    this.noIssueLength++;
                  }

              }
            }

            //  this for loop is used to bind length to the tabs name
            for (var j = 0; j < this.tabsContent.length; j++) {
              if (this.tabsContent[j].id == "errors") {
                this.tabsContent[j].text = "Errors"
                this.tabsContent[j].text += " (" + this.errorListLength + ")";
                //  this.tabsContent[j].text= "Errors ("+this.errorListLength+")"
              } else if (this.tabsContent[j].id == "potentialIssues") {
                this.tabsContent[j].text = "Potential Issues"
                this.tabsContent[j].text += " (" + this.potentialIssueLength + ")";
              } else if (this.tabsContent[j].id == "noIssues") {
                this.tabsContent[j].text = "No Issues"
                this.tabsContent[j].text += " (" + this.noIssueLength + ")";
              } else if (this.tabsContent[j].id == "allItems") {
                this.tabsContent[j].text = "All Items"
                this.tabsContent[j].text += " (" + this.totalRowsLength + ")";
              }
            }
    },
    async onProcessData() {
      if (this.saveDataButtonName == "Process" && this.selectedTemplate!=this.templateFileTypes.Entities && this.isUnmatchedItemsDisplayed == true) {
        if(this.checkDetailsToProcess()){
          
        if (this.checkHasHeaders()) {
           this.showLoader = true;
          // calling the unmatched items
          var response = await this.checkUnmatchedItems();
          if(response.isError){
            this.showLoader = false;
            this.notificationMessage =response.data.message;
            this.notificationKey = "error-default"
            this.notificationType = "error";
            this.showNotification = true;
          }else{
                //  if there are no unmatched items it goes for validations
              if (response.data.unmatchedentities.length == 0) {
                // calling import method to check validations and import data
                await this.importUploadedFileData()
                this.showLoader = false;

              } else {
                
                this.showLoader = false;
                this.showUnmatchedModal = true;
              }
          }
         
        }
        }
      } else if (this.saveDataButtonName == "Import" || this.selectedTemplate==this.templateFileTypes.Entities || this.isUnmatchedItemsDisplayed == false) {
        if(this.selectedTemplate==this.templateFileTypes.Entities){
          if (this.checkHasHeaders()) {
            this.showLoader = true;
            await this.importUploadedFileData()
            this.showLoader = false;
          }
        }else{
           this.showLoader = true;
          await this.importUploadedFileData()
          this.showLoader = false;

        }
      }
    },
    validationErrors(){
      // clear the messages
        for (var k = 0; k < this.convertedData.length; k++) {
              this.convertedData[k].message=[]
              this.convertedData[k].isPotentialIssueMessage=[]
              this.convertedData[k].isErrorMessage=[]
              this.convertedData[k].isError = false;
              this.convertedData[k].isPotentialIssue = false;
          for (let kc = 0; kc < this.convertedData[k].row.length; kc++) {
              this.convertedData[k].row[kc].isError = false;
              this.convertedData[k].row[kc].isPotentialIssue = false;
          }
        }
        // validationDetails
        if (this.validationDetails.errorcount > 0 || this.validationDetails.warningcount >0) {
                for (var i = 0; i < this.validationDetails.validation.length; i++) {
                  for (var j = 0; j < this.convertedData.length; j++) {
                    if (this.validationDetails.validation[i].rowid+1 == j) {
                      for (var c = 0; c < this.convertedData[j].row.length; c++) {
                        if (this.validationDetails.validation[i].colid == c) {
                          if (this.validationDetails.validation[i].level == 1) {
                            this.convertedData[j].row[c].isError = true;
                            this.convertedData[j].isError = true;
                            this.convertedData[j].isErrorMessage.push(this.validationDetails.validation[i].msg),
                            this.convertedData[j].message.push(this.validationDetails.validation[i].msg)
                          } else if (this.validationDetails.validation[i].level == 0) {
                            this.convertedData[j].row[c].isPotentialIssue = true;
                            this.convertedData[j].isPotentialIssue = true;
                            this.convertedData[j].message.push(this.validationDetails.validation[i].msg)
                            this.convertedData[j].isPotentialIssueMessage.push(this.validationDetails.validation[i].msg)
                          }
                        }
                      }
                    }
                  }
                }
                this.bulkData = this.convertedData;
              }
    },

    async checkUnmatchedItems() {
      await this.convertingJsonObjectToCheckUnmatchedItems();
      //  checkUnmatchedItems(this.checkUnmactchedObject)
      if(this.checkUnmactchedObject.data.length >0){
        this.checkUnmactchedObject.entitytypeid = parseInt(this.selectedEntityType) 
          this.checkUnmactchedObject.filetype=parseInt(this.selectedTemplate)
            await this.fetchUnmatchedItemDetails(this.checkUnmactchedObject);
            var response = this.getUnmatchedItemDetails;
            if (!response.isError) {
              if (this.getEntityTypes.length === 0) {
                await this.fetchEntityTypes();
              }
              this.entityList = response.data.entities;
              // entityListForDropDown
              for (var i = 0; i < this.entityList.length; i++) {
                this.entityList[i]["id"] = i;
                this.entityList[i]["text"] = this.entityList[i].entityname;
                this.entityList[i]["value"] = this.entityList[i].entityname;
              }
              this.convertedUnmatchedObject = await this.convertUnmatchedItems(
                response.data
              );
              return response;
            } else {
              return response;
            }

      }else{
         this.showLoader = false;
       let responseObject ={
         isError:true,
         data:{
           message:'No data found in the file. Please upload a file with at least one entry of data.'
         }
       }  
        // this.notificationMessage ='No data was received in the uploaded file';
        //         this.notificationType = "error";
        //         this.showNotification = true;
        //         setTimeout(() => {
        //           this.showNotification = false;
        //           this.notificationMessage = "";
        //         }, 5000);
                return responseObject;
      }
     
    },

      // check if template and entity level is selected on click of process
    checkDetailsToProcess(){
      if(this.selectedTemplate){
          if(this.selectedTemplate == 2 || this.selectedTemplate == 3){
            if(this.selectedEntityType != 0){
              return true
            }else{
              this.notificationMessage = "Please select Entity type";
              this.notificationKey = "error-default"
              this.notificationType = "error";
              this.showNotification = true;
              return false;
            }
          }else{
            return true;
          }
        }else{
          this.notificationMessage = "Please select Template";
          this.notificationType = "error";
          this.notificationKey = "error-default"
          this.showNotification = true;
          return false;
        }
    },
    // this method will check if header is present or not
    checkHasHeaders() {
        for (var i = 0; i < this.convertedData[0].row.length; i++) {
          if (this.convertedData[0].row[i].col.length == 0) {
            this.notificationMessage = "Please add all the headers";
            this.notificationType = "error";
            this.notificationKey = "error-default"
            this.showNotification = true;
            return false;
          } else {
            if (i == this.convertedData[0].row.length - 1) {
              return true;
            }
          }
        }
      
    },

    // this method is used to convert the format of json from the json recived from the server
    // and added extra keys for unmatched modal functions
    convertUnmatchedItems(data) {
      var unmatchedObj = [];
      for (var i = 0; i < data.unmatchedentities.length; i++) {
        var obj = {
          id: i,
          rowId: parseInt(data.unmatchedentities[i][0]),
          entityName: data.unmatchedentities[i][1],
          entityTypeId: null,
          matchedItem: "",
          searchValue: "",
          showSearchResult: false,
        };
        if(this.selectedEntityType != 0){
          obj.entityTypeId=this.selectedEntityType
        }
        unmatchedObj.push(obj);
      }
      return unmatchedObj;
    },

    // emited from the unmatched modal after matching the items and on click of continue
    // this method will replace the unmatched rows with the selected matching rows
    changeUnmatchedItems(unmatchedItemDetails) {
      this.showUnmatchedModal = false;
      for (var i = 0; i < this.convertedData.length; i++) {
        for (var j = 0; j < unmatchedItemDetails.length; j++) {
          if (unmatchedItemDetails[j].rowId == i) {
            if(unmatchedItemDetails[j].matchedItem.length == 0){
             this.convertedData[i].row[0].col = unmatchedItemDetails[j].entityName
            }else{
            this.convertedData[i].row[0].col =
              unmatchedItemDetails[j].matchedItem;

            }
          }
        }
      }
      this.onProcessData()
    },

    // this method will format the uploaded files "collection of arrays" to "Json Object" by adding some extra key values for functionality
    convertUploadedFileJson(fileData) {
      var object = [];
      for (var i = 0; i < fileData.length; i++) {
        var objRow = {
          row: [],
          isSelected: true,
          showErrorToolTip: false,
          showPotentialToolTip: false,
          isError: false,
          isPotentialIssue: false,
          message: [],
          isErrorMessage: [],
          isPotentialIssueMessage: [],
        };
        for (var j = 0; j < fileData[i].length; j++) {
          var obj = {
            col: fileData[i][j],
            isError: false,
            isPotentialIssue: false,
            message: "",
          };
          if (fileData[i][j] == undefined) {
            obj.col = "";
          }
          objRow.row.push(obj);
        }
        object.push(objRow);
      }
      return object;
    },
  

    // method will convert the uploaded file json object to collection of arrays in the format which supports api
    convertingJsonObjectToCheckUnmatchedItems() {
      this.checkUnmactchedObject.header = ["rowid"];
      this.checkUnmactchedObject.data = [];
      if (this.convertedData) {
        //  if (this.headerType != "firstRow") {
        //     this.convertedData.splice(0, 1);
        //   }
        if (this.checkHasHeaders()) {
          for (var i = 0; i < this.convertedData.length; i++) {
            var rowObj = [];
            for (var j = 0; j < this.convertedData[i].row.length; j++) {
              if (this.convertedData[i].row[j].col == undefined) {
                if (i == 0) {
                  this.checkUnmactchedObject.header.push("");
                } else {
                  rowObj.push("");
                }
              } else {
                if (i == 0) {
                  this.checkUnmactchedObject.header.push(
                    this.convertedData[i].row[j].col
                  );
                } else {
                  rowObj.push(this.convertedData[i].row[j].col);
                }
              }
            }
            if (i != 0) {
              rowObj.splice(0, 0, i.toString());
              this.checkUnmactchedObject.data.push(rowObj);
            }
          }
        } 
        //  this.bulkData.splice(0, 0, this.emptyArrayForNoHeader);
      }
    },

    // this will reconvert the json object to collection of arrays for validating the data
    convertJsonObjectToArray() {
      if (this.convertedData) {
        var objectComplete = [];
        this.importPayload.header=[]
         this.importPayload.data=[]
        for (var i = 0; i < this.convertedData.length; i++) {
          var rowObj = [];
          for (var j = 0; j < this.convertedData[i].row.length; j++) {
            rowObj.push(this.convertedData[i].row[j].col);
           
          }
          if(i==0){
            rowObj.push("Include")
          }else{
            if(this.convertedData[i].isSelected){
               rowObj.push(1)
            }else{
               rowObj.push(0)
            }
          }
           if(i == 0){
              this.importPayload.header=rowObj
              
            }else{
               this.importPayload.data.push(rowObj)
               
            }
          objectComplete.push(rowObj);
        }
      }
    },
    checkHighestIndexLength(uploadedData){
      for(var i=0;i<uploadedData.length;i++){
        if(uploadedData[i].length > this.HighestIndexLengthOfUploadedFile){
          this.HighestIndexLengthOfUploadedFile = uploadedData[i].length
        }
      }
    },
    checkUploadedData(uploadedData){
      var row=null
      for(var i=0;i<uploadedData.length;i++){
        if(uploadedData[i].length < this.HighestIndexLengthOfUploadedFile){
          if(row == null){
            row=i+1
          }else{
            row=row+","+(i+1)
          }
          // return row
        }
        // else{
        //   if(i == uploadedData.length-1){
        //     return null
        //   }
        // }
      }
      return row
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
#bulkImport .mds-modal .mds-section__header-container {
  margin-bottom: 0;
  // mds-space-2-x UniversNext for MORN Condensed Bold
}
.red-color {
  @include mds-icon-color(#ff0000);
  color: #ff0000;
}
.yellow-color {
  @include mds-icon-color(#f5c400);
  color: #f5c400;
}
#bulkImport::v-deep
  .bulkModal
  .error
  .mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo
  .mds-button__icon___VueMDS3Demo {
  @include mds-icon-color(#ff0000);
  // color:#ff0000;
  // fill: #ff0000;
  //   stroke: #ff0000;
}
#bulkImport::v-deep
  .bulkModal
  .potential
  .mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo
  .mds-button__icon___VueMDS3Demo {
  @include mds-icon-color(#f5c400);
  // color:#ff0000;
  // fill: #ff0000;
  //   stroke: #ff0000;
}
#bulkImport::v-deep
  .bulkModal
  .potentialInput
  .mds-input___VueMDS3Demo.mds-input--error___VueMDS3Demo,
.mds-input___VueMDS3Demo.mds-input--error___VueMDS3Demo:hover {
  box-shadow: inset 0 -2px 0 0 #f5c400;
}
#bulkImport::v-deep .searchResultClass .mds-section___VueMDS3Demo {
  border: none;
  box-shadow: none;
}
#bulkImport::v-deep
  .searchResultClass
  .mds-section___VueMDS3Demo
  .mds-section__content___VueMDS3Demo {
  padding: 0;
}
#bulkImport::v-deep .searchResultClass.mds-search-results___VueMDS3Demo .mds-section__header-container___VueMDS3Demo {
  margin-left: 0;
  padding-left: 4px;
  margin-bottom: 8px;
}
.toolTipListDiv{
max-height:400px;overflow:auto;
}
.red-color-background {
  background-color: #ff0000;
  background: #ff0000;
   width: 100%;
    line-break: anywhere;
    padding-right: 1px;
}
.yellow-color-background {
  background-color: #f5c400;
  background: #f5c400;
   width: 100%;
    line-break: anywhere;
    padding-right: 1px;
}
.red-color-background::after {
  border-right: 8px solid #ff0000 !important;
}
.yellow-color-background::after {
  border-right: 8px solid #f5c400 !important;
}
    
.file-details-container {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 2px solid #e5e5e5;
}
.bulk-left-col {
  border-right: 2px solid #e5e5e5;
  padding-left: 0;
}
.bulk-headers {
  font-weight: bold;
}
.search-results-component {
  visibility: hidden;
}
.show {
  visibility: visible;
  //   -webkit-animation: fadeIn 1s;
  //   animation: fadeIn 1s;
}
.bulk-right-col{
  height: 70vh;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

</style>
