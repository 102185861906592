<template>
  <div class="upload-file-section-container">
    
    <div class="upload-button-section">
      <div class="upload-button-container">
        <input class="file-upload-container" type="file" ref="fileUploadContainer" accept=".xlsx, .xls, .csv, .tsv" hidden>
        <button-component 
          class="upload-button"
          buttonName="Upload Data From a File" 
          buttonSize="small"
          buttonVariation="primary"
          :onClick="uploadFile"
        ></button-component>
      </div>
      <div class="accepted-file-types">
        <p class="accepted-file-types-text">.csv, .tsv, .xlsx accepted</p>
      </div>
    </div>

    <div class="file-upload-instructions">
      <p>
        You can upload any .csv, .tsv, or .xlsx file with any set of columns as long as it has one record per row. The next step will allow you to review how we interpret your file and make any corrections before saving the data.
      </p>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
import XLSX from 'xlsx';

export default {
  components: {
    ButtonComponent,
  },
  data() {
    return {
      uploadedFile: [],
    }
  },
  methods: {
    uploadFile() {
      // empty the array before uploading a new file
      this.uploadedFile = [];

      let fileInputElement = this.$refs.fileUploadContainer;
      fileInputElement.addEventListener("change", this.fileHandler, false);
      fileInputElement.click();
    },

    fileHandler() {
      // If file type is valid then, convert it to JSON object.
      if(this.isUploadedFileTypeValid(this.$refs.fileUploadContainer.files[0].name)) {
        this.uploadedFile = this.$refs.fileUploadContainer.files[0];
        if(this.uploadedFile.size === 0) {
          this.$emit("invalidFile", "Empty File Uploaded");
           this.$refs.fileUploadContainer.value = null;
        } else if(this.$refs.fileUploadContainer.files[0].name.split('.')[0].length > 90 ){
          this.$emit("invalidFile", "File name must be less than or equal to 90 characters.");
           this.$refs.fileUploadContainer.value = null;
        }else {
          this.convertToJson(this.uploadedFile);
          this.$refs.fileUploadContainer.value = null;
        }
      } else {
        this.$emit("invalidFile", "Invalid File Type");
         this.$refs.fileUploadContainer.value = null;
      }
      
    },

    isUploadedFileTypeValid(filename) {
      // regex pattern to validate the file type
      let acceptedFileTypes = /(\.csv|\.tsv|\.xlsx)$/i;

      if(acceptedFileTypes.exec(filename)) {
        return true;
      } else {
        return false;
      }
    },

    getUploadedFileType(filename) {
      return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    },

    convertXlsxToJson(file) {
      let reader = new FileReader();
      reader.readAsBinaryString(file);

      let xlsxObj = {};
      reader.onload = (event) => {
        let modifiedXlsxObj = [];
        let fileData = event.target.result;
        let highestIndexLengthOfUploadedFile = 0;

        // workbook refers to the complete excel document
        let workbook = XLSX.read(fileData, {type: "binary",cellText:false,cellDates:true});
        workbook.SheetNames.forEach((sheet) => {
          xlsxObj = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
            header: 1,raw:false,dateNF:'dd-mm-yyyy'
          });
        });
        
        modifiedXlsxObj.push(xlsxObj[0]);

        for (let i = 1; i < xlsxObj.length; i++) {
          let internalObj = xlsxObj[i];
          let emptyCellCount = 0;

          // to check if empty rows exists or not
          for(let k = 0; k < internalObj.length; k++) {
            if(!(internalObj[k] && internalObj[k].toString().trim())) {
              emptyCellCount++;
            }
          }

          if(emptyCellCount !== internalObj.length) {
            if(internalObj !== undefined && internalObj.length !== 0) {
              if((internalObj.length < xlsxObj[0].length) && (internalObj[internalObj.length] === undefined)) {
                xlsxObj[i][internalObj.length] = null;
              }
              for(var j=0;j<internalObj.length;j++){
                if(internalObj[j] == undefined || internalObj[j].length == 0){
                  xlsxObj[i][j]=null
                }
              }
              modifiedXlsxObj.push(xlsxObj[i]);
            }
          }
        }

        // ------------------to remove extra spaces in headers-------------------
        // for (let i = 0; i < obj.length; i++) {
        //   for(let j = 0; j < obj[i].length; j++) {
        //     let value = obj[i][j] + "";
        //     obj[i][j] = value.trim();
        //   }
        // }
        if(modifiedXlsxObj.length === 0 || (modifiedXlsxObj[0] === undefined && modifiedXlsxObj.length === 1 && modifiedXlsxObj[1] === undefined) || modifiedXlsxObj[0].length === 0) {
          this.$emit("invalidFile", "Empty File Uploaded");
        } else {
          for(let l = 0; l < modifiedXlsxObj.length; l++) {
            if(modifiedXlsxObj[l].length > highestIndexLengthOfUploadedFile) {
              highestIndexLengthOfUploadedFile = modifiedXlsxObj[l].length
            }
          }

          for(let i = 0; i < modifiedXlsxObj.length; i++) {
            for(let j = 0; j < highestIndexLengthOfUploadedFile; j++) {
              if(modifiedXlsxObj[i][j] === undefined) {
                modifiedXlsxObj[i][j] = null;
              }
            }
          }
          this.$emit("uploadedFileJson", modifiedXlsxObj, file.name);
        }
      }
    },

    convertCsvToJson(file) {
      let reader = new FileReader();
      reader.readAsBinaryString(file);

      let csvObj = [];
      reader.onload = (event) => {
        let modifiedCsvObj = [];
        let fileData = event.target.result;
        let lines=fileData.split("\n");
        let highestIndexLengthOfUploadedFile = 0;

        // separating headers and trimming all new line characters
        let headers = lines[0]
          .split(",")
          .map(element => {
            return element.trim();
          });
        csvObj.push(headers);

        // replacing "" to null and trimming all new line characters
        for(let i=1; i<lines.length; i++){
          csvObj.push(lines[i].trim().split(",").map(element => {
            if (element) {
              return element.trim();
            } else {
              return null;
            }
          }));
        }

        for (let i = 0; i < csvObj.length; i++) {
          let internalObj = csvObj[i];
          let emptyCellCount = 0;

          // to check if empty rows exists or not
          for(let k = 0; k < internalObj.length; k++) {
            if(internalObj[k] === null || internalObj[k].trim() === "") {
              emptyCellCount++;
            }
          }

          if(emptyCellCount !== internalObj.length) {
            if((internalObj.length < csvObj[0].length) && (internalObj[internalObj.length] === undefined)) {
              csvObj[i][internalObj.length] = null;
            }
            for(var j=0;j<internalObj.length;j++){
              if(internalObj[j] == undefined || internalObj[j].length == 0){
                csvObj[i][j]=null
              }
            }
            modifiedCsvObj.push(csvObj[i]);
          }
        }

        if(modifiedCsvObj.length === 0 || (modifiedCsvObj[0] === undefined && modifiedCsvObj.length === 1 && modifiedCsvObj[1] === undefined)) {
          this.$emit("invalidFile", "Empty File Uploaded");
        } else {
          for(let l = 0; l < modifiedCsvObj.length; l++) {
            if(modifiedCsvObj[l].length > highestIndexLengthOfUploadedFile) {
              highestIndexLengthOfUploadedFile = modifiedCsvObj[l].length
            }
          }

          for(let i = 0; i < modifiedCsvObj.length; i++) {
            for(let j = 0; j < highestIndexLengthOfUploadedFile; j++) {
              if(modifiedCsvObj[i][j] === undefined) {
                modifiedCsvObj[i][j] = null;
              }
            }
          }
          this.$emit("uploadedFileJson", modifiedCsvObj, file.name);
        }  
      }
    },

    convertTsvToJson(file) {
      let reader = new FileReader();
      reader.readAsBinaryString(file);

      let tsvObj = [];
      reader.onload = (event) => {
        let modifiedTsvObj = [];
        let fileData = event.target.result;
        let lines=fileData.split("\n");
        let highestIndexLengthOfUploadedFile = 0;

        // separating headers and trimming all new line characters
        let headers = lines[0]
          .split("\t")
          .map((element, index) => {
            if(index === 0) {
              element = element.replace("ï»¿", "");
            }
            return element.trim();
          });
        tsvObj.push(headers);

        // replacing "" to null and trimming all new line characters
        for(let i=1;i<lines.length;i++){
          tsvObj.push(lines[i].split("\t").map(element => {
            if (element) {
              return element.trim();
            } else {
              return null;
            }
          }));
        }

        for (let i = 0; i < tsvObj.length; i++) {
          let internalObj = tsvObj[i];
          let emptyCellCount = 0;

          // to check if empty rows exists or not
          for(let k = 0; k < internalObj.length; k++) {
            if(internalObj[k] === null || internalObj[k].length === 0) {
              emptyCellCount++;
            }
          }

          if(emptyCellCount !== internalObj.length) {
            if((internalObj.length < tsvObj[0].length) && (internalObj[internalObj.length] === undefined)) {
              tsvObj[i][internalObj.length] = null;
            }
            for(var j=0;j<internalObj.length;j++){
              if(internalObj[j] == undefined || internalObj[j].length == 0){
                tsvObj[i][j]=null
              }  
            }
            modifiedTsvObj.push(tsvObj[i]);
          }
        }

        if(modifiedTsvObj.length === 0 || (modifiedTsvObj[0] === undefined && modifiedTsvObj.length === 1 && modifiedTsvObj[1] === undefined)) {
          this.$emit("invalidFile", "Empty File Uploaded");
        } else {
          for(let l = 0; l < modifiedTsvObj.length; l++) {
            if(modifiedTsvObj[l].length > highestIndexLengthOfUploadedFile){
              highestIndexLengthOfUploadedFile = modifiedTsvObj[l].length
            }
          }

          for(let i = 0; i < modifiedTsvObj.length; i++) {
            for(let j = 0; j < highestIndexLengthOfUploadedFile; j++) {
              if(modifiedTsvObj[i][j] === undefined) {
                modifiedTsvObj[i][j] = null;
              }
            }
          }
          this.$emit("uploadedFileJson", modifiedTsvObj, file.name);
        }  
      }
    },

    convertToJson(file) {
      if(this.getUploadedFileType(file.name) === "xlsx" || this.getUploadedFileType(file.name) === "xls") {
        // converting .xlsx .xls file to JSON object.
        this.convertXlsxToJson(file);
      } else if(this.getUploadedFileType(file.name) === "csv") {
        // converting .csv file to JSON object.
        this.convertCsvToJson(file);
      } else if(this.getUploadedFileType(file.name) === "tsv") {
        // converting .tsv file to JSON object.
        this.convertTsvToJson(file);
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/css/styles.scss";

  p {
      margin: 0px;
  }
  
  .upload-file-section-container {
    height: 127px;
    border: 1px dashed #CCCCCC;
    color: #ccbebe;
    display: grid;
    grid-template-columns: 212px auto;
    border-radius: 6px;
  }

  .upload-button-section {
    margin-left: 17px;
    display: grid;
    grid-template-rows: 61px auto;
  }

  .upload-button-container {
    display: grid;
    align-content: flex-end;
  }

  .upload-button {
    width: 170px;
    height: 29px;
    margin-top: 32px;
    display: grid;
    align-content: center;
    justify-content: center;
  }

  .accepted-file-types {
    margin-top: 11.88px;
  }

  .accepted-file-types-text {
    @include mds-body-text-s($bold: false);
    height: 18px;
    width: 123px;
    color: #5E5E5E;
    font-size: 14px;
    line-height: 18px;
  }

  .file-upload-instructions {
    @include mds-body-text-s($bold: false);
    width: 407px;
    height: 84px;
    color: #1E1E1E;
    font-size: 16px;
    line-height: 21px;
    margin-top: 17px;
  }
</style>