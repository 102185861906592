// @author vijay Badha

<template>
  <div id="unmatchedModal" @click="windowClick($event)">
    <mds-modal
      title="Unmatched Items"
      v-model="toggle"
      :width="'900px'"
      action-required
    >
      We couldn’t find matches for these entries in your file. How should we
      handle them?
      <div style="margin-top: 16px;padding: 2%;height: 500px;">

<mds-table spacious>
    <mds-thead>
        <mds-th 
          v-for="(header,headerIndex) in headers"
          :key="headerIndex"
          :sorted="header.sorted" 
          :sortable="header.sortable"
          @mds-th-sort-changed="onHeaderSort(headerIndex,header.fieldName)"
          > 
          {{header.text}}
        </mds-th>
    </mds-thead>
    <mds-tbody>
        <mds-tr v-for="(rows,rowIndex) in showRows" :key="rowIndex">
            <mds-td
             v-for="(header,headerRowIndex) in headers"
            :key="headerRowIndex"
            :style="header.fieldName === 'matchedItem' ? 'position: relative;overflow: visible;':''"
            > 
            <template  v-if="header.fieldName === 'entityTypeId'"
              style="padding: 1%">
                    <mds-select  :disabled="selectedEntityTypeId != 0" :key="selectDropDown" v-model="rows[header.fieldName]"  hidden-label label="Type" @change="onTypeChange(rows[header.fieldName])">
                  <option value="">Select a type</option>
                  <option v-for="(option, index) in getEntityTypes" 
                          :key="index" :value="option.entitytypeid">
                          {{ option.entitytypedescription }}
                  </option>
              </mds-select>
            </template>
            <template v-else-if="header.fieldName === 'matchedItem'">
              <span v-if="rows[header.fieldName]" style="width: 340px">
                {{ rows[header.fieldName] }}
                <span style="margin-left: 20px">
                  <mds-button
                    variation="icon-only"
                    icon="pencil"
                    type="button"
                    :id="'search' + rows.id"
                    size="small"
                    class="searchInput"
                    @click="openFocusSearchResult(rows.id,rows);rows[header.fieldName]=''"
                  ></mds-button>
                </span>
              </span> 
               <mds-search-field
                  v-else
                  :disabled="!rows.entityTypeId || listEntity.length == 0"
                  v-model="rows.searchValue"
                  :value="rows.searchValue"
                  :id="'search' + rows.id"
                  class="searchInput"
                  placeholder="Find matching entity"
                  variation="input"
                  label="Search"
                  @focus="openFocusSearchResult(rows.id,rows)"
                  @input="
                    filterSearchResult('search' + rows.id, $event)
                  "
                ></mds-search-field>
                 <!-- <div style="position: absolute;">
                  
                </div> -->
                <mds-search-results
                    v-if="rows.showSearchResult && listEntity.length > 0"
                    :width="'300px'"
                    class="searchResultClass search-results-component"
                  > 
                    <mds-section
                      border="none"
                      :size="7"
                      :bold="true"
                      title="Entity Name"
                      class="searchResultClass"
                    style="height: 300px; overflow: hidden"
                    >
                    <mds-list-group  class="searchResultClass">
                        <mds-list-group-item class="searchResultClass"
                          v-for="(entity,entityIndex) in rowsFilteredResult"
                          :key="entityIndex"
                          :text="entity.entityname"
                          @mds-list-item-clicked="
                            searchListClick(
                              entity,
                              rows.id
                            )
                          "
                        ></mds-list-group-item>
                      </mds-list-group>
                    </mds-section>
                  </mds-search-results>
            </template>
            <template v-else>
             {{ rows[header.fieldName] }}

            </template>
            </mds-td>
        </mds-tr>
    </mds-tbody>
</mds-table>
        <!-- <mds-data-table
          :header-configs="headers"
          :row-data="showRows"
          size="medium"
          @mds-data-table-sort-change="onSort($event)"
          style="position: relative;"
        >
          <template v-slot:body-cell="cellData">
            <template  v-if="cellData.headerConfig.fieldName === 'entityTypeId'"
              style="padding: 1%">
              <mds-select  :disabled="selectedEntityTypeId != 0" :key="selectDropDown" v-model="cellData.rowData.entityTypeId"  hidden-label label="Type" @change="onTypeChange(cellData.rowData.entityTypeId)">
            <option value="">Select a type</option>
            <option v-for="(option, index) in getEntityTypes" 
                    :key="index" :value="option.entitytypeid">
                    {{ option.entitytypedescription }}
            </option>
        </mds-select>
            </template>
            <template
              v-if="cellData.headerConfig.fieldName === 'matchedItem'"
              style="padding: 1%"
            >
              <p v-if="cellData.rowData.matchedItem" style="width: 340px">
                {{ cellData.rowData.matchedItem }}
                <span style="margin-left: 20px">
                  <mds-button
                    variation="icon-only"
                    icon="pencil"
                    type="button"
                    :id="'search' + cellData.rowData.id"
                    size="small"
                    class="searchInput"
                    @click="openFocusSearchResult(cellData.rowData.id,cellData.rowData);cellData.rowData.matchedItem=''"
                  ></mds-button>
                </span>
              </p> 
              <div style="position: absolute;width:300px">
                <mds-search-field
                  v-if="!cellData.rowData.matchedItem"
                  :disabled="!cellData.rowData.entityTypeId || listEntity.length == 0"
                  v-model="cellData.rowData.searchValue"
                  :value="cellData.rowData.searchValue"
                  :id="'search' + cellData.rowData.id"
                  class="searchInput"
                  placeholder="Find matching entity"
                  variation="input"
                  label="Search"
                  @focus="openFocusSearchResult(cellData.rowData.id,cellData.rowData)"
                  @input="
                    filterSearchResult('search' + cellData.rowData.id, $event)
                  "
                ></mds-search-field>
                <div style="position: absolute;">
                  <mds-search-results
                    v-if="cellData.rowData.showSearchResult && listEntity.length > 0"
                    :width="'300px'"
                    class="searchResultClass search-results-component"
                  >
                    <mds-section
                      border="none"
                      :size="7"
                      :bold="true"
                      title="Entity Name"
                      class="searchResultClass"
                    style="height: 300px; overflow: hidden"
                    >
                      <mds-list-group class="searchResultClass">
                        <mds-list-group-item class="searchResultClass"
                          v-for="entity in rowsFilteredResult"
                          :key="entity.entityid"
                          :text="entity.entityname"
                          @mds-list-item-clicked="
                            searchListClick(
                              entity,
                              cellData.rowData.id
                            )
                          "
                        ></mds-list-group-item>
                      </mds-list-group>
                    </mds-section>
                  </mds-search-results>
                </div>
              </div>
            </template>
            
          </template>
        </mds-data-table> -->
      </div>
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <button-component
            :onClick="close"
            buttonName="Cancel"
            buttonVariation="secondary"
          ></button-component>
          <button-component
            :onClick="changeUnmatchedItems"
            buttonName="Continue"
            buttonVariation="primary"
          ></button-component>
        </mds-button-container>
      </template>
    </mds-modal>
    <loader-component v-if="showLoader"></loader-component>
  </div>
</template>
<script>
import MdsModal from "@mds/modal";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
// import { MdsDataTable } from "@mds/data-table";
import MdsSearchField from "@mds/search-field";
import { MdsSearchResults } from "@mds/search-results";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsSection from "@mds/section";
import MdsSelect from '@mds/select'
import { mapGetters,mapActions } from "vuex";
import LoaderComponent from '../ui_component/loaderComponent.vue';
import { MdsTable, MdsThead, MdsTh, MdsTr,MdsTbody, MdsTd} from '@mds/data-table-4.0.2';
export default {
  components: {
    MdsModal,
    MdsButtonContainer,
    MdsButton,
    ButtonComponent,
    // MdsDataTable,
    MdsSearchField,
    MdsSearchResults,
    MdsListGroup,
    MdsListGroupItem,
    MdsSection,
    MdsSelect,
    LoaderComponent,
    MdsTable, MdsThead, MdsTh, MdsTr,MdsTbody, MdsTd
  },
  props: {
    unmatchedItems: {
      required: true,
    },
    selectedEntityTypeId: {
      required: true,
    },
  },
  data() {
    return {
       sortOrder: 1, 
        sortColIndex: 0 ,
      entityTypeId:null,
      showLoader:false,
      selectDropDown:0,
      rowsFilteredResult: [],
      toggle: true,
      listEntity:[],
      headers: [
        {
          fieldName: "entityName",
          text: "Entity Name",
          width: "300px",
          sortable: true,
          sorted: 1,
        },
        {
          fieldName: "entityTypeId",
          text: "Type",
          width: "200px",
        },
        {
          fieldName: "matchedItem",
          text: "Matched Entity",
          width: "400px",
        },
        
      ],

      showRows: [],
       entityDropDownPayload: {
        "entitytypeid": 0
      }
    };
  },
   computed:{
...mapGetters("entity", [
      "getEntityTypes"
    ]),
  },
 async mounted(){
    this.showRows = this.unmatchedItems.sort((a, b) =>
        a[`entityName`] >= b[`entityName`] ? this.sortOrder : -this.sortOrder
      );
    if(this.selectedEntityTypeId !=0){
      this.showLoader = true
     await this.getEntityDropDownList(this.selectedEntityTypeId)
     this.showLoader=false
    }
  },
  methods: {
     ...mapActions("entity", [
      "fetchDropdownListEntity"
    ]),
    windowClick(e) {
      if (
        e.target.className != "mds-search-field__input___VueMDS3Demo" &&
        e.target.classList[0] != "searchResultClass" && e.target.parentElement.parentNode.classList[0]!="searchResultClass" && e.target.localName != "svg"
      ) {
        for (var i = 0; i < this.showRows.length; i++) {
          this.showRows[i].showSearchResult = false;
        }
      }
    },
    getEntityList() {
      return this.entityList;
    },
    close() {
      this.$emit("closeUnmatchedItems");
    },
    changeUnmatchedItems() {
      this.$emit("selectedUnmatchedItems", this.showRows);
    },
    // onSort(arg) {
    //   console.log(arg)
    //   console.log("**************");
    //   const order = arg.sortOrder;
    //   this.showRows = this.unmatchedItems.sort((a, b) =>
    //     a[`${arg.sortField}`] <= b[`${arg.sortField}`] ? order : -order
    //   );
    // },
    onHeaderSort(colIndex,header) {
             this.sortOrder = this.sortOrder == -1 ? 1 : -this.sortOrder;
              if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                    this.$set(this.headers[this.sortColIndex], 'sorted', 0);
                    this.sortOrder = 1;
        }
        this.sortColIndex = colIndex;
        this.$set(this.headers[colIndex], 'sorted', this.sortOrder);
      this.showRows = this.unmatchedItems.sort((a, b) =>
        a[`${header}`] >= b[`${header}`] ? this.sortOrder : -this.sortOrder
      );
    },
    async openFocusSearchResult(rowid,rowData) {
      if(this.entityTypeId != rowData.entityTypeId){
        this.showLoader = true
        await this.getEntityDropDownList(rowData.entityTypeId)
        this.showLoader = false
      }else{
         this.rowsFilteredResult=this.listEntity
      }
      for (var i = 0; i < this.showRows.length; i++) {
        if (this.showRows[i].id == rowid) {
          this.showRows[i].showSearchResult = true;
        }else{
            this.showRows[i].showSearchResult = false
        }
      }
    },
    filterSearchResult(id, event) {
      if (event != undefined && event.length > 0) {
        this.rowsFilteredResult = this.listEntity.filter((item) =>
          item.entityname.toLowerCase().includes(event.toLowerCase())
        );
        //  this.$el.querySelector('.'+id).classList.add("show");
      } else {
        this.rowsFilteredResult = this.listEntity;
        //    this.$el.querySelector('.'+id).classList.remove("show");
      }
    },
    searchListClick(entity, rowid) {
      for (var i = 0; i < this.showRows.length; i++) {
        if (this.showRows[i].id == rowid) {
          this.showRows[i].matchedItem = entity.entityname;
          this.showRows[i].showSearchResult = false;
        }
      }
    },
   async getEntityDropDownList(id){
     this.entityDropDownPayload.entitytypeid = id
       var response = await this.fetchDropdownListEntity(
        this.entityDropDownPayload
      );
      if(!response.isError){
       this.entityTypeId=id
       this.listEntity = response.data
       this.rowsFilteredResult=this.listEntity
     }
      return response
    },
   async onTypeChange(e){
      if(this.entityTypeId != e){
      this.showLoader = true
     await this.getEntityDropDownList(e)
     
     this.showLoader=false

      }else{
         this.rowsFilteredResult=this.listEntity
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
#unmatchedModal::v-deep .mds-section___VueMDS3Demo
  .mds-section__header-container___VueMDS3Demo {
  margin-bottom: 0px;
}
#unmatchedModal::v-deep .mds-data-table__cell___VueMDS3Demo {
  padding: 1%;
}
// #unmatchedModal::v-deep .mds-search-results___VueMDS3Demo {
//   padding: 0;
// }
#unmatchedModal::v-deep .mds-search-results___VueMDS3Demo
  .mds-section__header-container___VueMDS3Demo {
  margin-bottom: 8px !important;
  margin-top: 16px;
}
#unmatchedModal::v-deep .search-results-component .mds-section___VueMDS3Demo {
  border: none;
  box-shadow: none;
}
</style>