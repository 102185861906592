<template>
  <div class="bulk-edit-parent-container">
    <div class="main-screen-section">
      <div class="bulk-edit-section">
      <mds-layout-grid>
        <mds-row class="bulk-edit-header-container">
          <mds-col>
            <h2 class="bulk-edit-header">Import Data</h2>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      </div>

      <div class="upload-file-container-main-section">
        <div class="upload-file-section">
          <upload-file-component @invalidFile="invalidFileUpload" @uploadedFileJson="fileJson"></upload-file-component>
        </div>

        <div class="dowload-template-section">
          <div class="instructions-header">
            <p>Download a Template to Start</p>
          </div>
          <div class="download-templates">
            <p v-for="template in getTemplatesList" :key="template.templateid">
              <a href="#" @click="downloadTemplate(template)">
                {{ template.templatename }}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div class="import-history-container">
        <div class="import-history-header-section">
          <h4 class="import-history-header">
            Import History
          </h4>
        </div>

        <div class="import-history-table-section"
          :style="cssVariables"
        >
          <div class="table_container">
            <mds-data-table
              :key="componentKeyForTable"
              v-if="importHistoryItems > 0"
              show-sortable
              :header-configs="tableHeaders"
              :row-data="paginatedRows"
              row-hover
              @mds-data-table-sort-change="sortColumnsOnClick($event)"
            >
              <template v-slot:body-cell="cellData">
                <template v-if="cellData.colIndex === 2">
                  {{ convertUTCDate(cellData.rowData[cellData.headerConfig.fieldName]) }}
                </template>

                <template v-if="cellData.colIndex === 5">
                  <div class="import-history-status">
                    <span class="needs-review-icon-container" v-if="cellData.rowData[cellData.headerConfig.fieldName] === 'NeedsReview'">
                      <mds-icon
                        class="needs-review-icon"
                        name="alert-fill"
                        size="small"
                      >
                      </mds-icon>
                    </span>
                    <span class="needs-review-text-container">
                      {{ cellData.rowData[cellData.headerConfig.fieldName] }}
                    </span>
                  </div>
                </template>

                <template v-if="cellData.colIndex === 6">
                  <div class="import-history-item-action">
                    <mds-button 
                      v-if="cellData.rowData[cellData.headerConfig.fieldName] === 'Imported' || cellData.rowData[cellData.headerConfig.fieldName] === 'Active'"
                      variation="icon-only" 
                      icon="download" 
                      type="button"
                      @click="importHistoryItemAction('download', cellData.rowData.fileid, cellData.rowData.filename)"
                    ></mds-button>
                    <span class="needs-review-action-group" v-if="cellData.rowData[cellData.headerConfig.fieldName] === 'NeedsReview'">
                      <span class="needs-review-review-icon">
                        <mds-button 
                          variation="icon-only" 
                          icon="pencil" 
                          type="button"
                          @click="reviewStagingData(cellData.rowData)"
                        ></mds-button>
                      </span>
                      <span class="needs-review-download-icon">
                        <mds-button 
                          variation="icon-only" 
                          icon="download" 
                          type="button"
                          @click="importHistoryItemAction('download', cellData.rowData.fileid, cellData.rowData.filename)"
                        ></mds-button>
                      </span>
                    </span>
                  </div>
                </template>
              </template>
            </mds-data-table>
          </div>
          <mds-pagination
            class="pagination-component"
            :key="componentKey"
            v-if="importHistoryItems > 0"
            show-items-info 
            show-items-select 
            :total-items="totalItems"
            :pageSize="pageSize"
            :pageSizes="[10, 20, 50]"
            @mds-pagination-page-changed="paginateTable"
            style="margin-top:10px"	
          ></mds-pagination>
        </div>
      </div>
      <div class="loader-container">
        <loader-component 
          v-if="!responseReceived"
        ></loader-component>
         <loader-component 
          v-if="showLoader"
        ></loader-component>
      </div>
      <div class="bulk-import-modal-container">
        <import-file v-if="showImportModalPopup" :fileData="uploadedData" :fileName="uploadedFileName" @importModalClose="closeImportModal" @dataImportedStatus="uploadedDataImportedStatus" @incorrectUploadedData="incorrectUploadedData" :reviewData="reviewDataDetails" :isReviewAction="reviewActionStatus" :stagingId="stagingId" :showTemplateDropDown="true" :fileTemplateId="null" :disableHeaderSelectDropDown="false" :showPotentialIssues="true" :isUnmatchedItemsDisplayed="true" screenName="BulkOperations" :editAllFields="false">
        </import-file>
        <bulk-import-modal v-if="false" :fileData="uploadedData" :fileName="uploadedFileName" @importModalClose="closeImportModal" @dataImportedStatus="uploadedDataImportedStatus" @incorrectUploadedData="incorrectUploadedData" :reviewData="reviewDataDetails" :isReviewAction="reviewActionStatus" :stagingId="stagingId"></bulk-import-modal>
      </div>
      <!-- <div class="footer-component-container">
        <footer-component v-if="responseReceived"></footer-component>
      </div> -->
    </div>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :title="errorTitle"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>
  </div>
</template>

<script>
import UploadFileComponent from "../ui_component/uploadFileComponent.vue";
import { MdsDataTable } from '@mds/data-table'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LoaderComponent from "../ui_component/loaderComponent.vue";
import { MdsButton } from '@mds/button';
import BulkImportModal from '../common_components/BulkImportModal.vue';
import MdsIcon from '@mds/icon';
// import FooterComponent from "../common_components/FooterComponent";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import MdsPagination from '@mds/pagination';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid"
import ImportFile from '../common_components/ImportFile.vue';
import { dateFormatterMixin } from '../../mixins/date.js';
export default {
  components: {
    UploadFileComponent,
    MdsDataTable,
    LoaderComponent,
    MdsButton,
    BulkImportModal,
    MdsIcon,
    // FooterComponent,
    NotificationComponent,
    MdsPagination,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    ImportFile
  },

   mixins: [ dateFormatterMixin ],

  props: {
    menuwidth: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showLoader:false,
      componentKey: 0,
      componentKeyForTable: 1000000,
      reviewDataDetails:[],
      stagingId:0,
      reviewActionStatus: false,
      showImportModalPopup:false,
      uploadedData:null,
      uploadedFileName: '',
      firstItem: 0,
      lastItem: 0,
      currentPage: null,
      currentShow: null,
      paginatedRows: [],
      showNotification: false,
      notificationType:'',
      notificationMessage: '',
      errorTitle: '',
      pageSize: 10,
      tableHeaders:
      [
        {
            "fieldName":"filename",
            "text":"File Name",
            "width":"16%",
            "align":"left",
            "sortable":true,
        },
        {
            "fieldName":"username",
            "text":"User Name",
            "width":"15%",
            "align":"left",
            "sortable":true,
        },
        {
            "fieldName":"addedat",
            "text":"Imported (UTC)",
            "width":"7%",
            "align":"left",
            "sortable":true,
            "sorted":-1
        },
        {
            "fieldName":"rows",
            "text":"Total Rows",
            "width":"5%",
            "align":"left",
        },
        {
            "fieldName":"importedrows",
            "text":"Rows Imported",
            "width":"5%",
            "align":"left",
        },
        {
            "fieldName":"statustext",
            "text":"Status",
            "width":"8%",
            "align":"left",
        },
        {
            "fieldName":"statustext",
            "text":"Actions",
            "width":"3%",
            "align":"right",
        }
      ],
    }
  },
  async mounted() {
     
    this.paginateTable({
      firstItem: 1,
      lastItem: 10,
      page: 1,
      pageSize: 10,
    });
    this.setShowCustomTemplateOption();
    await this.setListOfTemplates();
  },
  watch: {
    // $route: "setImportHistory",
  },
  computed: {
    ...mapGetters('bulkEdit', ['getImportHistory', 'getPageNumber', 'getPageSize', 'getFile', 'getTemplatesList', 'getTemplate']),
    ...mapGetters('bulkOperations', ['getStagingData']),

    responseReceived() {
      if(this.getImportHistory.status) {
        return true;
      } else {
        return false;
      }
    },

    importHistoryItems() {
      if(this.getImportHistory.data !== undefined) {
        if(this.getImportHistory.data.list.length > 0) {
          return this.getImportHistory.data.list.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },

    totalItems() {
      return this.getImportHistory.data.totalitems;
    },

    cssVariables() {
      return {
        "--menuWidth": this.menuwidth,
      };
    },

  },
  methods: {
    // temporary change
    ...mapActions('bulkEdit', ["setImportHistory", "setFileDownload", "setListOfTemplates", "setTemplateDownload"]),
  ...mapActions("bulkOperations", ["fetchStagingDetails"]),
  ...mapMutations('bulkOperations', ["setStagingData"]),
    ...mapMutations('bulkEdit', ["setPageNumber", "setPageSize", "setOrderby", "setIsAscending", "setFileId", "setFileToNull", "setShowCustomTemplateOption", "setTemplateId", "setTemplateToNull"]),

    getModelData() {
      let response = this.getImportHistory.data;
      let rowData = {
        rows: []
      };
      
      let id = 0;

      if(response !== undefined) {
        response.list.forEach(item => {
          item.id = id++;
          item.username = (item.username === null || item.username === "") ? '\u2014' : item.username;
          rowData.rows.push(item);
        });
      }
      this.paginatedRows = rowData.rows;
    },

    async paginateTable(arg) {
      this.setPageNumber(arg.page);
      this.pageSize = arg.pageSize;
      this.setPageSize(this.pageSize);
      await this.setImportHistory();
      if(this.getModelData()) {
        this.paginatedRows = this.getModelData().slice(arg.firstItem - 1, arg.lastItem);
      }
    },

    async sortColumnsOnClick(event) {
      this.setPageNumber(1);

      this.setOrderby(event.sortField);
      if(event.sortOrder === 1) {
        this.setIsAscending(true);
      } else {
        this.setIsAscending();
      }
      
      await this.setImportHistory();
      if(this.getModelData()) {
        this.paginatedRows = this.getModelData().slice(0, 10);
      }
      this.componentKey++;
    },

   async reviewStagingData(data){
     this.showLoader=true
      // bulkuploadstagingdataid
      let params={
        stagingId:data.bulkuploadstagingdataid
      }
      this.setStagingData(null)
      await this.fetchStagingDetails(params)
      if(this.getStagingData){
        if(!this.getStagingData.isError){
          this.stagingId=data.bulkuploadstagingdataid
        this.uploadedFileName=this.getStagingData.data.filedto.filename
        this.reviewDataDetails=this.getStagingData
        this.reviewActionStatus = true;
        this.showLoader=false
        this.showImportModalPopup = true;
      }
      }else{
        console.log("null");  
      }
      
    },
    // for file download
    async importHistoryItemAction(action, fileId, filename) {
      if(action === 'download') {
        this.setFileId(fileId);
        await this.setFileDownload();
        if(this.getFile.data !== undefined) {
          const url = window.URL.createObjectURL(new Blob([this.getFile.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }))
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);

          // temporary change
          this.setFileToNull();
        } else {
          this.invalidFileUpload("File Not Available");
        }
      } else {
        this.reviewActionStatus = true;
        this.showImportModalPopup = true;
      }
    },

    // for template download
    async downloadTemplate(template) {
      this.setTemplateId(template.templateid);
      await this.setTemplateDownload();
      if(this.getTemplate.data !== undefined) {
        const url = window.URL.createObjectURL(new Blob([this.getTemplate.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${template.templatename}.xlsx`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);

        // temporary change
        this.setTemplateToNull();
      } else {
        this.invalidFileUpload("Template Not Available");
      }
    },

    closeImportModal(){
      this.reviewActionStatus = false;
      this.showImportModalPopup = false
    },
    fileJson(value, fileName){
      // for(let i=0;i<value.length;i++){
      //   for(let j=0;j<value[i].length;j++){
      //     if(value[i][j] != null && value[i][j] != undefined && value[i][j].length != 0){
      //          value[i][j] = value[i][j].toString().replace(/\u2013|\u2014/g, "-")
      //       }
      //   }
      // }
      if(value){
        this.showImportModalPopup=true
        this.uploadedFileName = fileName;
        this.uploadedData=value
      }
    },

    invalidFileUpload(message) {
      this.errorTitle = message;
      this.showNotification = true;
      this.notificationKey = "error-default";
      this.notificationType =  "error"
      if(this.errorTitle === "Invalid File Type") {
        this.notificationMessage = `Please upload a valid file from any of the following file types only. 
                                    .xlsx, .csv or .tsv`
      } else if(this.errorTitle === "Empty File Uploaded") {
        this.notificationMessage = `Empty file upload not allowed. Please upload a file with atleast one row.`
      } else if(this.errorTitle === "Template Not Available") {
        this.notificationMessage = `Template is not available for download.`
      } else if(this.errorTitle === "File Not Available") {
        this.notificationMessage = `File is not available for download.`
      } 
    },
    incorrectUploadedData(value){
      this.closeImportModal()
      this.errorTitle=''
       this.notificationMessage = "Incorrect data in the uploaded file at line number "+value;
      this.notificationType = "error";
      this.notificationKey = "error-default";
      this.showNotification = true;
    },
    uploadedDataImportedStatus(value){
      this.closeImportModal()
      this.errorTitle=''
      this.notificationMessage = value.data.message;
      this.notificationType = "success";
      this.notificationKey = "success-default";
      this.showNotification = true;
      // temporary changes
      this.setImportHistory();
      this.sortColumnsOnClick({sortField:"addedat", sortOrder:-1});
      this.componentKeyForTable++;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.setPageSize(10);
    next();
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

  .bulk-edit-parent-container {
    margin-left: 16px;
    margin-right: 16px ;
  }

  .breadcrumbs-container {
    @include mds-body-text-s($bold: false);
    margin-top: 19.88px;
    height: 18px;
    width: 75px;
    color: #5E5E5E;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;

    .highlighted-link {
      color: inherit;
    }
  }

  .bulk-edit-header {
    @include mds-level-2-heading($bold: false);
    color: #1E1E1E;
    height: 36px;
    font-size: 32px;
    font-weight: 200;
    letter-spacing: 0;
    line-height: 36px;
    padding: 0px;
    margin-top: 16px;
    margin-bottom: 21px;
  }

  .upload-file-container-main-section {
    display: flex;
  }

  .dowload-template-section {
    @include mds-body-text-s($bold: false);
    height: 126px;
    width: 100%;
    color: #1E1E1E;
    font-size: 16px;
    letter-spacing: 0;
    margin-left: 35px;
    line-height: 21px;

    p {
      margin: 0px;
      padding: 0px;
    }

    a {
      color:inherit;
    }

    .download-templates {
      height: 110px;
      overflow-y: scroll;
    }
  }

  .import-history-container {
    .import-history-header {
      @include mds-level-4-heading($bold: false);
      height: 27px;
      width: 117px;
      font-size: 23px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 27px;
      padding: 0px;
      margin-top: 38.37px;
      margin-bottom: 23px;
      color: #1E1E1E;
    }
  }

  .needs-review-action-group {
    display: grid;
    grid-template-columns: auto auto;
  }

  .needs-review-icon {
    @include mds-icon-color($mds-feedback-color-error);
  }

  .import-history-status {
    display: flex;
  }

  .needs-review-icon-container {
    display: grid;
    margin: 3px 3px 0 0;
  }

  .import-history-table-section {
    margin-top: 13px;

      .table_container{
        height: auto; 
        max-height: 47vh; 
        overflow: auto;
      }
  }

  ::v-deep .mds-data-table--show-sortable___VueMDS3Demo 
    .mds-data-table__header-cell--sort___VueMDS3Demo 
    .mds-data-table__header-cell-inner___VueMDS3Demo {
      border: none;
    }

  .pagination-component {
    position: fixed;
    width: calc(100vw - 32px - var(--menuWidth));
    bottom: 0;
  }

</style>