import { render, staticRenderFns } from "./BulkImportModal.vue?vue&type=template&id=893b14dc&scoped=true&"
import script from "./BulkImportModal.vue?vue&type=script&lang=js&"
export * from "./BulkImportModal.vue?vue&type=script&lang=js&"
import style0 from "./BulkImportModal.vue?vue&type=style&index=0&id=893b14dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "893b14dc",
  null
  
)

export default component.exports